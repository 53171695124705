import React, { useMemo, useState, useEffect } from 'react'
import {
  Modal,
  Fade,
  Box,
  Button,
  InputBase,
  Typography,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles } from './style'
import { connect } from 'redux-zero/react'
import GroupList from './GroupsList'
import SearchIcon from '@material-ui/icons/Search'
import { EditTagName } from './EditTagName'
import actions from '../../store/actions'
import useResponse from './useResponse'
import useTag from './useTag'
import Keys from '../../utils/Keys'
import UsersList from './UsersList/index'
import { SelectedGroups } from './SelectedGroups'
import { SelectedUsers } from './SelectedUsers'
import { updateGroupTags, updateUserTags, isAllowToDeleteTag } from './utils'

export const EditTagModal = ({
  open,
  handleClose,
  selectedTag,
  groups,
  users,
  setSelectedTag,
  selected_rid,
  retailTags,
  updateRetailTags,
  handleAlert,
  logout,
  loading,
  setIsLoading,
  setUsers,
  setGroups,
}) => {
  const classes = useStyles()
  const [tagName, setTagName] = useState(
    (selectedTag && selectedTag.name) || ''
  )
  const [searchValue, setSearchValue] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [checkedGroupIds, setCheckedGroupIds] = useState(
    (isEdit && selectedTag && selectedTag?.groups) || []
  )
  const [checkedUsersIds, setChekedUsersIds] = useState(
    isEdit && selectedTag && selectedTag?.users ? selectedTag?.users : []
  )
  const [selection, setSelection] = useState('groups')

  const { handleUpdateTag, handleDeleteTag } = useTag()
  const { handleSubmissionResponse } = useResponse({
    handleAlert: handleAlert,
    logout: logout,
    callback: handleClose,
  })

  const handleCloseModal = () => {
    handleClose()
    setSearchValue('')
    setSelectedTag(null)
    setCheckedGroupIds([])
    setChekedUsersIds([])
    setIsEdit(false)
  }

  const groupsObjectToArray = useMemo(
    () =>
      groups !== null
        ? Object.entries(groups)
            .map(([id, { name, parent, tags }]) => ({
              id: id,
              title: name,
              parentId: parent,
              tags: tags,
            }))
            .sort((a, b) =>
              a.title.toLowerCase().localeCompare(b.title.toLowerCase())
            )
        : [],
    [groups]
  )

  const searchResults = useMemo(() => {
    if (searchValue) {
      const lowerCaseSearchValue = searchValue.toLowerCase()

      if (selection === 'groups') {
        return groupsObjectToArray.filter(group =>
          group.title.toLowerCase().includes(lowerCaseSearchValue)
        )
      } else {
        return users.filter(user => {
          const uidMatches = user[Keys.UID]
            .toLowerCase()
            .includes(lowerCaseSearchValue)
          const firstNameMatches =
            user.first_name &&
            user.first_name.toLowerCase().includes(lowerCaseSearchValue)
          const lastNameMatches =
            user.last_name &&
            user.last_name.toLowerCase().includes(lowerCaseSearchValue)

          return uidMatches || firstNameMatches || lastNameMatches
        })
      }
    }

    return []
  }, [searchValue, groupsObjectToArray, users])

  const handleSubmit = async () => {
    const addedGroups = selectedTag.groups
      ? checkedGroupIds.filter(id => !selectedTag.groups.includes(id))
      : checkedGroupIds
    const deletedGroups = selectedTag.groups
      ? selectedTag.groups.filter(group => !checkedGroupIds.includes(group))
      : []

    const addedUsers = selectedTag.users
      ? checkedUsersIds.filter(id => !selectedTag.users.includes(id))
      : checkedUsersIds
    const deletedUsers = selectedTag.users
      ? selectedTag.users.filter(user => !checkedUsersIds.includes(user))
      : []

    // Prevent adding and removing groups or users at the same time
    if (
      (addedGroups.length > 0 && deletedGroups.length > 0) ||
      (addedUsers.length > 0 && deletedUsers.length > 0)
    ) {
      handleAlert({
        text: `You can't add and remove contexts at the same time`,
        type: Keys.ALERT_ERROR,
      })
      return
    }

    const body = {
      rid: selected_rid,
      tag_id: selectedTag.sk,
      name: tagName,
      groups_to_add: addedGroups,
      groups_to_delete: deletedGroups,
      users_to_add: addedUsers,
      users_to_delete: deletedUsers,
    }

    try {
      handleUpdateTag(handleSubmissionResponse, body, setIsLoading)
      const copiedTags = [...retailTags]
      const updatedTags = copiedTags.map(tag =>
        tag.name === selectedTag.name
          ? {
              ...tag,
              name: tagName,
              groups: checkedGroupIds,
              users: checkedUsersIds,
            }
          : tag
      )

      await updateRetailTags(updatedTags)

      if (addedUsers.length > 0) {
        setUsers(updateUserTags(addedUsers, 'add', users, selectedTag))
      }
      if (deletedUsers.length > 0) {
        setUsers(updateUserTags(deletedUsers, 'delete', users, selectedTag))
      }
      if (addedGroups.length > 0) {
        setGroups(updateGroupTags(addedGroups, 'add', groups, selectedTag))
      }
      if (deletedGroups.length > 0) {
        setGroups(updateGroupTags(deletedGroups, 'delete', groups, selectedTag))
      }
      handleCloseModal()
    } catch (e) {
      console.log(e)
    }
  }

  const handleDelete = () => {
    const deletedGroups = selectedTag.groups ? selectedTag.groups : []
    const deletedUsers = selectedTag.users ? selectedTag.users : []
    const body = {
      rid: selected_rid,
      tag_id: selectedTag.sk,
      name: tagName,
      groups_to_delete: deletedGroups,
      users_to_delete: deletedUsers,
    }
    try {
      handleDeleteTag(handleSubmissionResponse, body, setIsLoading)
      const copiedTags = [...retailTags]
      const updatedTags = copiedTags.filter(tag => tag.sk !== selectedTag.sk)

      updateRetailTags(updatedTags)

      if (deletedUsers.length > 0) {
        setUsers(updateUserTags(deletedUsers, 'delete', users, selectedTag))
      }

      if (deletedGroups.length > 0) {
        setGroups(updateGroupTags(deletedGroups, 'delete', groups, selectedTag))
      }
      handleCloseModal()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (selectedTag && selectedTag.name) {
      setTagName(selectedTag.name)
    }
    if (selectedTag && selectedTag.groups) {
      setCheckedGroupIds(selectedTag.groups)
    }
    if (selectedTag && selectedTag.users) {
      setChekedUsersIds(selectedTag.users)
    }
  }, [selectedTag])

  return (
    <>
      <Modal
        className={classes.tagModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => handleCloseModal()}
      >
        <Fade in={open}>
          <Box className={classes.modalContent}>
            <Box display="flex" flexDirection="column" px="10px">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h5" className={classes.text}>
                  Edit Tag
                </Typography>
                <Typography variant="h5" className={classes.text}>
                  {selectedTag?.sk || ''}
                </Typography>
                <IconButton onClick={() => handleCloseModal()}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </Box>
              <Box>
                <Typography fontSize="14px" style={{ color: 'red' }}>
                  You can't add and remove contexts at the same time *
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box
                style={{
                  width: '50%',
                  padding: '15px',
                  display: 'flex',
                  maxHeight: '74vh',
                  flexDirection: 'column',
                  border: '1px solid #cbc8c8',
                  borderRadius: '30px',
                }}
              >
                <Typography>Choose Context</Typography>
                <RadioGroup
                  row
                  aria-label="selection"
                  name="selection"
                  value={selection}
                  onChange={e => {
                    setSelection(e.target.value)
                    setSearchValue('')
                  }}
                >
                  <FormControlLabel
                    value="groups"
                    control={<Radio />}
                    label="Groups"
                  />
                  <FormControlLabel
                    value="users"
                    control={<Radio />}
                    label="Users"
                  />
                </RadioGroup>
                <Box
                  className={classes.editTagWrapper}
                  style={{ marginTop: '20px' }}
                >
                  <InputBase
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                    className={classes.tagInput}
                    placeholder={`Search ${selection}`}
                  />
                  <SearchIcon />
                </Box>
                {selection === 'groups' && (
                  <GroupList
                    groupsObjectToArray={groupsObjectToArray}
                    searchResults={searchResults}
                    searchValue={searchValue}
                    checkedIds={checkedGroupIds}
                    setCheckedIds={setCheckedGroupIds}
                  />
                )}
                {selection === 'users' && (
                  <UsersList
                    users={users}
                    searchResults={searchResults}
                    searchValue={searchValue}
                    checkedIds={checkedUsersIds}
                    setCheckedIds={setChekedUsersIds}
                  />
                )}
              </Box>
              <Box
                style={{
                  width: '50%',
                  padding: '15px',
                  display: 'flex',
                  maxHeight: '74vh',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '19px',
                }}
              >
                <Box width="100%">
                  <EditTagName
                    tagName={tagName}
                    setTagName={setTagName}
                    retailTags={retailTags}
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    selectedTag={selectedTag}
                    loading={loading}
                  />

                  {checkedGroupIds && (
                    <SelectedGroups
                      groups={groups}
                      checkedIds={checkedGroupIds}
                    />
                  )}
                  {checkedUsersIds && (
                    <SelectedUsers users={users} checkedIds={checkedUsersIds} />
                  )}
                </Box>
                <Box
                  style={{
                    width: '100%',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Button
                    disabled={!isAllowToDeleteTag()}
                    onClick={() => handleDelete()}
                    className={`${classes.btn} ${classes.delete}`}
                  >
                    Delete TAG
                  </Button>
                  <Button
                    disabled={isEdit === true}
                    onClick={() => handleSubmit()}
                    style={{
                      background:
                        isEdit === true ? 'gray' : 'rgb(112, 112, 180)',
                    }}
                    className={classes.btn}
                  >
                    Save Changes
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

const mapStateToProps = ({
  selectedTag,
  groups,
  users,
  setSelectedTag,
  selected_rid,
  retailTags,
  updateRetailTags,
  handleAlert,
  logout,
  loading,
  setIsLoading,
}) => ({
  selectedTag,
  groups,
  users,
  setSelectedTag,
  selected_rid,
  retailTags,
  updateRetailTags,
  handleAlert,
  logout,
  loading,
  setIsLoading,
})

export default connect(mapStateToProps, actions)(EditTagModal)
